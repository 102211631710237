<template>
    <ui-create
        name="companies"
        v-model="form"
        :title="title"
        :id="id"
    >
        <template #body >
            <ui-crud
                v-model="form"
                :rows="rows"
            />
        </template>
    </ui-create>
</template>
<script>
import uiCreate from '@/templates/create'
import {uiCrud} from '@/components/ui'
export default {
    name: 'vwcompanyCreate',
    components: {
        uiCreate,
        uiCrud
    },
    data(){
        return {
            form: {
                nome: '',
                razaosocial: null,
                cnpj: '',
                inscricao: '',
                email: '',
                telefone: '',
                endereco: '',
                bairro: '',
                cidade: '',
                uf: '',
                cep: ''
            },

            rows: [
                [
                    {
                        name: 'nome',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Nome da Empresa',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 6,
                            xl: 8
                        }
                    },
                    {
                        name: 'razaosocial',
                        value: '',
                        type: 'string',
                        field: 'autocomplete',
                        label: 'Razão Social',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 6,
                            xl: 4
                        },
                    },
                ], [
                    {
                        name: 'cnpj',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Cnpj',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 8,
                            lg: 8,
                            xl: 8
                        }
                    },
                    {
                        name: 'inscricao',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Inscrição Estadual',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 4,
                            lg: 4,
                            xl: 4
                        }
                    },
                ], [
                    {
                        name: 'email',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Email de Contato',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 6,
                            xl: 6
                        }
                    },
                    {
                        name: 'telefone',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Telefone de Contato',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 6,
                            xl: 6
                        }
                    },
                ], [{
                        name: 'cep',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Cep',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 2,
                            xl: 2
                        }
                    }, {
                        name: 'endereco',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Endereço (Rua, Nº)',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 3,
                            xl: 3
                        }
                    }, {
                        name: 'bairro',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Bairro',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 3,
                            xl: 3
                        }
                    }, {
                        name: 'cidade',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Endereço (Rua, Nº)',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 8,
                            lg: 3,
                            xl: 3
                        }
                    }, {
                        name: 'uf',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Estado',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 4,
                            lg: 1,
                            xl: 1
                        }
                    }]
            ],
        }
    },

    computed:{
        id(){
            return this.$route.params.id || 0
        },

        title(){
            return this.id ? `Editar empresa: ${this.id}` : 'Cadastrar Empresa'
        }
    },

    mounted(){
        this.$root.$on('load-data-companies', (data) => {
            this.form = data
        })
    }
}
</script>